/* eslint-disable import/no-anonymous-default-export */
import alexis from './alexis.jpeg';
import alpha from './alpha.png';
import antoine from './antoine.jpg';
import apple from './apple.svg';
import apple02 from './apple02.svg';
import beta from './beta.png';
import bientot from './bientot.png';
import eloi from './eloi.jpg';
import expo from './expo.png';
import expo02 from './expo02.png';
import feature from './feature.png';
import flutter from './flutter.svg';
import google from './google.svg';
import homeCards from './home_cards.png';
import homeHero from './home_hero.png';
import idee from './idee.png';
import javascript from './javascript.png';
import kylian from './kylian.png';
import mockup from './mockup.png';
import plane from './plane.png';
import prog from './programmation-web.png';
import prototype from './prototype.png';
import react from './react.png';
import scene from './scene.png';
import theo from './theo.png';
import thomas from './thomas.png';
import typescript from './typescript.svg';

export default {
  homeHero,
  homeCards,
  feature,
  apple,
  apple02,
  google,
  flutter,
  typescript,
  expo,
  expo02,
  javascript,
  react,
  scene,
  mockup,
  kylian,
  antoine,
  theo,
  eloi,
  alexis,
  thomas,
  plane,
  alpha,
  beta,
  bientot,
  idee,
  prog,
  prototype,
};
