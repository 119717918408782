import styles from '../styles/Global';

const Button = ({
  assetUrl,
  link,
  downloadStore,
  disable,
  target = '_self',
  children,
}) => {
  const handleClick = () => {
    if (!disable) window.open(link, target);
  };

  return (
    <div
      className={`${disable ? styles.btnDisable : ''} ${
        styles.btnBlack
      } w-auto ${styles.textLeft}`}
      onClick={handleClick}
    >
      <img src={assetUrl} alt='' className={styles.btnIcon} />
      <div className={'flex flex-col justify-start ml-4'}>
        {children ? (
          children
        ) : (
          <>
            <p className={`${styles.btnText} font-normal text-xs`}>
              {'Prochainement sur'}
            </p>
            <p className={`${styles.btnText} font-bold text-sm`}>
              {downloadStore === 'apple' ? 'App Store' : 'Google Play'}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Button;
