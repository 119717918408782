import assets from '../assets';
import styles from '../styles/Global';

const TimelineCard = ({ iconUrl, iconText, date, locked }) => {
  return (
    <div className={`${styles.featureCard}`}>
      <img
        src={iconUrl}
        alt={iconText}
        className={`${styles.featureImg}  ${
          locked ? styles.timelineLocked : ''
        }`}
      />
      <p className={styles.featureText}>{iconText}</p>
      <p className={styles.timelineText}>{date}</p>
    </div>
  );
};

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>
            Nos avancés
          </h1>
          <p className={`${styles.pText} ${styles.blackText}`}>
            Nous avons faits du chemin jusqu'ici et il nous en reste encore
            beaucoup à parcourir
          </p>
        </div>
        <div className={`${styles.flexWrap}`}>
          <TimelineCard
            iconUrl={assets.idee}
            iconText={'Idéeation'}
            date={'Novembre 2021'}
          />
          <TimelineCard
            iconUrl={assets.prototype}
            iconText={'Prototype'}
            date={'Décembre 2021'}
          />
          <TimelineCard
            iconUrl={assets.prog}
            iconText={'Développement'}
            date={'Septembre 2022'}
          />
          <TimelineCard
            iconUrl={assets.alpha}
            iconText={'Préparation Alpha'}
            date={'Janvier 2023'}
          />
          <TimelineCard
            iconUrl={assets.beta}
            iconText={'Préparation Beta'}
            date={'Mai 2023'}
          />
          <TimelineCard
            iconUrl={assets.beta}
            iconText={'Lancement de la Beta'}
            date={'Juin 2023'}
          />
          <TimelineCard
            iconUrl={assets.beta}
            iconText={'Finalisation de la Beta'}
            date={'Septembre 2023'}
          />
          <TimelineCard
            iconUrl={assets.bientot}
            iconText={'Coming Soon'}
            date={'N/A'}
            locked
          />
        </div>
      </div>
    </div>
  );
};

export default Features;
