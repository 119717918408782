import assets from '../assets';
import styles from '../styles/Global';
import Button from './Button';

const FeatureCard = ({ iconUrl, iconText }) => {
  return (
    <div className={styles.featureCard}>
      <img src={iconUrl} alt={iconText} className={styles.featureImg} />
      <p className={styles.featureText}>{iconText}</p>
    </div>
  );
};

const Teams = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>L'équipe</h1>
          <p className={`${styles.pText} ${styles.blackText}`}>
            Notre équipe est composée de 6 personnes
          </p>
        </div>
        <div className={`${styles.flexWrap}`}>
          <FeatureCard iconUrl={assets.kylian} iconText={'Kylian'} />
          <FeatureCard iconUrl={assets.antoine} iconText={'Antoine'} />
          <FeatureCard iconUrl={assets.theo} iconText={'Theo'} />
          <FeatureCard iconUrl={assets.eloi} iconText={'Eloi'} />
          <FeatureCard iconUrl={assets.alexis} iconText={'Alexis'} />
          <FeatureCard iconUrl={assets.thomas} iconText={'Thomas'} />
        </div>
        <div>
          <h1 className={`${styles.h2Text} ${styles.blackText}`}>
            Contactez-nous
          </h1>
          <Button assetUrl={assets.plane} link={'#contact'}>
            <p className={`${styles.whiteText}`}>Envoyez nous un message</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Teams;
