import assets from '../assets';
import styles from '../styles/Global';
import Button from './Button';

const SectionWrapper = ({
  title,
  description,
  showBtn,
  mockupImg,
  banner,
  reverse,
}) => {
  return (
    <div
      className={`min-h-screen ${styles.section} ${
        reverse ? styles.bgWhite : styles.bgPrimary
      } ${banner}`}
    >
      <div
        className={`flex items-center ${
          reverse ? styles.boxReverseClass : styles.boxClass
        } w-11/12 sm:w-full minmd:w-3/4`}
      >
        <div
          className={`${styles.descDiv} ${
            reverse ? 'fadeRightMini' : 'fadeLeftMini'
          } ${reverse ? styles.textRight : styles.textLeft}`}
        >
          <h1
            className={`${styles.h1Text} ${
              reverse ? styles.blackText : styles.whiteText
            }`}
          >
            {title}
          </h1>
          <p
            className={`${styles.descriptionText} ${
              reverse ? styles.blackText : styles.whiteText
            }`}
          >
            {description}
          </p>
          {showBtn && (
            <div className={`flex items-stretch space-x-4`}>
              <Button
                assetUrl={assets.google}
                link='AndroidLink'
                downloadStore={'google'}
                disable
                target={'_blank'}
              />
              <Button
                assetUrl={assets.apple}
                link='AppleLink'
                downloadStore={'apple'}
                disable
                target={'_blank'}
              />
            </div>
          )}
        </div>
        <div className={`flex-1 ${styles.flexCenter} p-8 sm:px-0`}>
          <img
            src={mockupImg}
            alt='mockup'
            className={`${styles.sectionImg} ${
              reverse ? 'fadeLeftMini' : 'fadeRightMini'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper;
