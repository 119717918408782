const styles = {
  whiteText: 'text-white',
  blackText: 'text-tertiary',

  bgWhite: 'bg-white',
  bgPrimary: 'bg-primary',

  boxClass: 'flex-row md:flex-col',
  boxReverseClass: 'flex-row-reverse md:flex-col-reverse',

  textLeft: 'text-left',
  textRight: 'text-right',

  h1Text:
    'font-bold font-IBMPlex text-5xl sm:text-4xl minmd:text-6xl minlg:text-8xl leading-12 minmd:leading-13',
  h2Text:
    'font-bold font-IBMPlex text-3xl sm:text-2xl minmd:text-5xl minlg:text-7xl leading-10 minmd:leading-11',
  pText:
    'my-5 minmd:my-10 font-IBMPlex font-light text-xl minmd:text-3xl minlg:text-4xl minmd:leading-16',
  descriptionText:
    'my-5 minmd:my-10 font-IBMPlex font-light text-xl minmd:text-3xl minlg:text-4xl minmd:leading-16',
  btnText: 'text-white font-IBMPlex minmd:text-lg',

  btnPrimary:
    'bg-primary mt-4 py-4 px-6 text-white text-lg minmd:text-2xl font-IBMPlex font-medium rounded-lg hover:shadow-2xl',
  btnBlack:
    'bg-black flex items-center py-2 px-4 rounded-md mt-2 w-fit cursor-pointer',
  btnDisable: 'cursor-not-allowed',

  section: 'flex justify-center items-center p-16 sm:p-8',
  subSection: 'flex items-center w-full minmd:w-3/4',
  descDiv: 'flex-1 w-full flex justify-start flex-col md:mb-10 ',

  flexCenter: 'flex justify-center items-center',
  flexWrap: 'flex justify-center flex-wrap',

  fullImg: 'w-full h-full object-cover',
  sectionImg: 'w-full h-full minmd:w-11/12 minmd:h-11/12 object-contain',
  btnIcon: 'w-5 h-5 minmd:w-10 minmd:h-10 object-contain',

  featureImg: 'w-20 h-20 minlg:w-40 minlg:h-40 object-contain mb-1',
  featureText:
    'font-semibold font-IBMPlex text-base minlg:text-3xl text-tertiary',
  featureCard:
    'bg-white m-8 p-6 rounded-md flex justify-around items-center flex-col w-40 h-40 minlg:w-80 minlg:h-80',

  timelineText: 'font-IBMPlex text-base minlg:text-3xl text-tertiary',
  timelineLocked: 'opacity-20',

  formInput:
    'w-full p-3 border-1 border-solid border-gray-50 rounded box-border mt-1.5 mb-4 resize-y',
  formSubmit:
    'bg-black text-white py-3 px-5 rounded border-none cursor-pointer hover:bg-slate-400',
};

export default styles;
