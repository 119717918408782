import assets from '../assets';
import styles from '../styles/Global';

const FeatureCard = ({ iconUrl, iconText }) => {
  return (
    <div className={styles.featureCard}>
      <img src={iconUrl} alt={iconText} className={styles.featureImg} />
      <p className={styles.featureText}>{iconText}</p>
    </div>
  );
};

const Features = () => {
  return (
    <div className={`${styles.section} ${styles.bgPrimary} banner03`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.whiteText}`}>
            Technologies
          </h1>
          <p className={`${styles.pText} ${styles.whiteText}`}>
            Freshen a été développé à l'aide d'une technologie multi-platforme,
            Flutter.
          </p>
        </div>
        <div className={`${styles.flexWrap}`}>
          <FeatureCard iconUrl={assets.flutter} iconText={'Flutter'} />
          <FeatureCard iconUrl={assets.typescript} iconText={'Typescript'} />
        </div>
      </div>
    </div>
  );
};

export default Features;
