import styles from '../styles/Global';

const Contact = () => {
  return (
    <div id='contact' className={`${styles.section} banner02`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.whiteText}`}>
            Contactez-nous
          </h1>
        </div>
        <div>
          <form action='#'>
            <label for='fname'>Nom</label>
            <input
              type='text'
              id='fname'
              name='firstname'
              placeholder='Votre nom'
              className={styles.formInput}
            />

            <label for='lname'>Email</label>
            <input
              type='text'
              id='lname'
              name='lastname'
              placeholder='Votre email'
              className={styles.formInput}
            />

            <label for='subject'>Message</label>
            <textarea
              id='subject'
              name='subject'
              placeholder='Votre message'
              className={styles.formInput}
            ></textarea>

            <input type='submit' value='Submit' className={styles.formSubmit} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
