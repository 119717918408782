import assets from './assets';
import {
  Contact,
  Download,
  Features,
  SectionWrapper,
  Teams,
  Timeline,
} from './components';
import styles from './styles/Global';

const App = () => {
  return (
    <>
      <SectionWrapper
        title='Freshen'
        description='Tissez des liens stylés tout en réinventant la mode de demain'
        showBtn
        mockupImg={assets.homeHero}
        banner='banner'
      />
      <SectionWrapper
        title='Fonctionalité de géolocalisation'
        description='Trouvez vos friperies sur une carte et dirigez-vous facilement vers celle-ci grâce à notre système de géolocalisation'
        mockupImg={assets.homeCards}
        reverse
      />
      <Features />
      <Teams />
      <SectionWrapper
        title='Deux utilisations possible'
        description="L'application permet dans un premier temps à trouver les friperies qui possède la perle rare soit par notre système de filtre soit par notre système de réseau social mais elle permet également de suivre vos économies en ressource monétaire et environnementale."
        mockupImg={assets.mockup}
        banner='banner02'
      />
      <Timeline />
      <Contact />
      <Download />
      <div className='px-4 py-2 justify-center items-center bg-primary flex-col text-center banner04'>
        <p className={`${styles.pText} ${styles.whiteText}`}>
          Fait avec amour par <span className='bold'>l'équipe de Freshen</span>
        </p>
      </div>
    </>
  );
};

export default App;
