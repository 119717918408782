import assets from '../assets';
import styles from '../styles/Global';
import Button from './Button';

const Download = () => {
  return (
    <div className={`${styles.section} ${styles.bgWhite}`}>
      <div className={`${styles.subSection} flex-col text-center`}>
        <div>
          <h1 className={`${styles.h1Text} ${styles.blackText}`}>
            Téléchargez l'application
          </h1>
          <p className={`${styles.pText} ${styles.blackText}`}>
            Disponible sur IOS et Android
          </p>
          <div className={'flex items-stretch space-x-4 justify-center'}>
            <Button
              assetUrl={assets.google}
              link='AndroidLink'
              downloadStore={'google'}
              disable
              target={'_blank'}
            />
            <Button
              assetUrl={assets.apple}
              link='AppleLink'
              downloadStore={'apple'}
              disable
              target={'_blank'}
            />
          </div>
          <div className={styles.flexCenter}>
            <img src={assets.scene} alt={''} className={styles.fullImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
